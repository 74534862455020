@import './vairables';
@import '../fonts/GeneralSans_Complete/Fonts/WEB/css/general-sans.css';




body{
  font-family: 'GeneralSans-Variable';
  background-color: $pimaryBg;
}
img{
  max-width: 100%;
}


header{
  position: fixed;
  top: 40px;
  right: 0;
  left: 0;
  z-index: 1030;
  .navbar{
    background: #2F2F2F;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    min-height: 80px;
    padding-inline: 20px;
    .nav-item{
      font-family: 'GeneralSans-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #fff;
      position: relative;
      &:first-child{
        margin-right: 72px;
      }
      .nav-link{
        font-family: 'GeneralSans-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #fff;
      }
      .HomeActive{
        position: relative;
        font-family: 'GeneralSans-Semibold';
        font-weight: 600;
        font-size: 16px;
        &:after{
          content: "";
          position: absolute;
          width: 68px;
         height: 6px;
          border-radius: 8px 8px 0px 0px;
          bottom: -65%;
          background:  #D9D9D9 ;
          left: 50%;
          right: 50%;
          transform: translate(-50%,50%);

        }

      }
    }
    .btn-contact{
      background: $primaryColor;
      color: #fff;
      min-width: 180px;
      font-family: 'GeneralSans-Semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      min-height: 48px;
      &:hover{
        border: none;
      }
    }
    .contactactive{
      background: #0E0E0E;
      color: #fff;
    }
    .mobil-nav{
      display: none;
    }
  }
}
.herosection{
  position: relative;
  background: url("../img/heroBG.png");
  padding-top: 168px;
  padding-bottom:70px ;
  .heading{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    position: relative;
    div{
      position: relative;
      padding-bottom: 1.5rem;
      h1{
        font-family: 'GeneralSans-Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 97px;
        color: #fff;
        span{
          color: $primaryColor;
        }
      &:after{
        content: "";
        position: absolute;
        width: 130px;
        height: 2px;
        left: 0;
        bottom: 0;
        background: #FFFFFF66;


      }
      }

    }
    p{
      color: #fff;
      font-family: 'GeneralSans-Regular';
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: left;
      padding-top: 1.5rem;
      margin-bottom: 0rem;

    }
    .scrolIcon{
      position: absolute;
      left:0px ;
      bottom: 0px;
    }
  }

  .heroImg{
    display: flex;
    justify-content: flex-end;
  }

}
.aboutUsSection{
  .row{
    background: #171717;
    border-radius: 1rem;
    padding-block: 4.5rem;
    img{
      max-width: 100%;
      margin-inline: auto;
    }
    .aboutDescription{
      padding-right: 54px;
      .title{
        position: relative;
        margin-bottom: 2rem;
        h3{
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 2.5rem;
          line-height: 97px;
          text-align: left;
          margin-bottom: 0rem;
          color: #fff;
          span{
            color: $primaryColor;
          }
          &:after{
            content: "";
            position: absolute;
            width: 130px;
            height: 2px;
            left: 0;
            bottom: 0;
            background: #FFFFFF66;


          }
        }

      }
      p{
        color: #fff;
        font-family: 'GeneralSans-Regular';
        font-size: 1.125rem;
        line-height: 2rem;
        text-align: left;
        margin-bottom: 0rem;

      }

    }

  }
}
.services{
  padding-block: 4.5rem;
  .row{
    .servicesintro{

      .title{
        position: relative;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 2.5rem;
          line-height: 97px;
          text-align: left;
          margin-bottom: 0rem;
          color: #fff;
          span{
            color: $primaryColor;
          }
          &:after{
            content: "";
            position: absolute;
            width: 130px;
            height: 2px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            bottom: 0;
            background: #FFFFFF66;
          }
        }

      }
      p{
        color: #fff;
        font-family: 'GeneralSans-Regular';
        font-size: 1.125rem;
        line-height: 2rem;
        text-align: center;
        margin-bottom: 4rem;

      }
    }
    .card{
      background: #171717;
      padding-top: 64px;
      padding-bottom: 28px;
      padding-left: 24px;
      padding-right: 24px;
      height: 100%;
      border-radius: 1rem;
      img{
        width: auto;
        margin: auto;
        max-height: 172px;
        margin-bottom: 52px;
      }
      .card-img-top0{
          content: url("../img/cardImg1.png");
        max-height: 172px;
      }.card-img-top1{
          content: url("../img/cardImg2.png");
      }.card-img-top2{
          content: url("../img/cardImg3.png");
      }
      .card-body{
        padding: 0;
        .card-title{
          color: #fff;
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          margin-bottom: 32px;
          line-height: 100%;
          span{
            color: $primaryColor;
          }
        }
        .card-text{
          color: #fff;
          font-family: 'GeneralSans-Regular';
          font-size: 1.125rem;
          line-height: 2rem;
          text-align: center;
          margin-bottom: 0rem;
        }
      }
      &:hover{
        background: #535B8C;
      }
    }
  }

}
.OurClient{
  padding-block: 4.5rem;
  background: #171717;
  .row{
    .servicesintro{
      .title{
        position: relative;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 2.5rem;
          line-height: 97px;
          text-align: left;
          margin-bottom: 0rem;
          color: #fff;
          span{
            color: $primaryColor;
          }
          &:after{
            content: "";
            position: absolute;
            width: 130px;
            height: 2px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            bottom: 0;
            background: #FFFFFF66;
          }
        }

      }
      p{
        color: #fff;
        font-family: 'GeneralSans-Regular';
        font-size: 1.125rem;
        line-height: 2rem;
        text-align: center;
        margin-bottom: 4rem;

      }
    }
    .Doublecard{
      .card1{
        padding: 47px 36px;
        margin-bottom: 20px;
        height: auto;
        img{
          max-height: 116px;
          max-height: 116px;
          object-fit: contain;
          margin: auto;
          max-width: 100%;
        }
      }
      .card2{
        padding: 30px 59px;
        height: auto;
        img{
          max-height: 151px;
          min-height: 151px;
          object-fit: contain;
          margin: auto;
          max-width: 100%;
        }
      }
    }
    .card{
      border-radius: 1rem;
       background: #0E0E0E;
      padding-block: 48px;
      height: 100%;
      img{
        width: auto;
        margin: auto;
      }
      .card-img-top0{
          content: url("../img/cardImg1.png");
        max-height: 172px;
      }.card-img-top1{
          content: url("../img/cardImg2.png");
      }.card-img-top2{
          content: url("../img/cardImg3.png");
      }
      .card-body{
        .card-title{
          color: #fff;
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 100%;
          span{
            color: $primaryColor;
          }
        }
        .card-text{
          color: #fff;
          font-family: 'GeneralSans-Regular';
          font-size: 1.125rem;
          line-height: 2rem;
          text-align: center;
          margin-bottom: 0rem;
        }
      }
    }
    .cardgif{
      padding-block:48px ;
      .cardimgtop1{
        min-height: 277px;
        max-height: 277px;
      }
      .cardimgtop2{
        min-height: 247px;
        max-height: 247px;
      }
      .cardimgtop1{
        min-height: 277px;
      }

      img{
        max-width: 255px;
        min-height: 264px;
        margin-top: 0px;
        margin-bottom: 20px;
      }
      svg{
        margin-inline: auto;
        margin-top: auto;
      }
    }
  }

}
.Testimonials{
  padding-block: 4.5rem;
  background: #171717;
  .row{
    .servicesintro{
      .title{
        position: relative;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 2.5rem;
          line-height: 97px;
          text-align: left;
          margin-bottom: 0rem;
          color: #fff;
          span{
            color: $primaryColor;
          }
          &:after{
            content: "";
            position: absolute;
            width: 130px;
            height: 2px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            bottom: 0;
            background: #FFFFFF66;
          }
        }

      }
      p{
        color: #fff;
        font-family: 'GeneralSans-Regular';
        font-size: 1.125rem;
        line-height: 2rem;
        text-align: center;
        margin-bottom: 4rem;

      }
    }
  }
  .slider{
    position: relative;
    padding-block: 5rem;
    background: url("../img/stars.png");
    background-size: cover;
    .featureslider_nextarrow{
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translate(50%,50%);
    }
    .featureslider_prevarrow{
      z-index: 10;
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translate(50%,50%);

    }
    .slide{
      position: relative;
      .avatarimg{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 200px;
          border-radius: 50%;
          margin-bottom: 2rem;
        }
        h5{
          font-weight: 600;
          font-size: 24px;
          line-height: 100%;
          color: #fff;
        }
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: $primaryColor;
          margin-bottom: 2.5rem;
        }
      }
      .rating{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-inline: 5px;
        }
      }
      .description{
        width: 80%;
        position: relative;
        margin-inline:auto ;
        padding-block: 40px;
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          text-align: center;
          color: #FFFFFF;
        }
        &:before{
          position: absolute;
          background : url(../img/startcolin.png) no-repeat;
          content: '';
          background-size: 82px 64px;
          display: inline-block;
          width: 82px;
          height: 64px;
          padding: 0 7px;
          left: -20px;
          top: 0px;

        }
        &::after{
          position: absolute;
          background : url(../img/endcolin.png) no-repeat;
          background-size: 82px 64px;
          display: inline-block;
          width: 82px;
          height: 64px;
          content : "";
          bottom: 20px;
          right: 0;
          padding: 0 7px;

        }


      }
    }
  }

}
.footer1{

  padding-top: 42px;
  padding-bottom: 18px;
  .widget1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img{
      margin-bottom: 25px;
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      text-align: left;
      max-width: 350px;
    }
  }
  .widget2{
    h6{
      font-family: 'GeneralSans-Semibold';
      color: $primaryColor;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    ul{
      padding-left: 0px;
      li{
        list-style: none;
        font-family: 'GeneralSans-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        text-align: left;
        margin-bottom: 1rem;
        a{
          text-decoration: none;
          color: #fff;

        }
      }
    }
  }
  .widget3{
    h6{
      font-family: 'GeneralSans-Semibold';
      color: $primaryColor;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    p{
      text-align: left;
      color: #fff;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
    }
    .input-group{
     background: #171717;
      min-height: 56px;
      border: none;
      border-radius: 0.5rem;
    input{
      background: #171717;
      border: none;
      color: #fff;
      font-size: 18px;

    }
      .input-group-text{
        background: #171717;
        border-color: transparent;
        color: #9DA6CE;
        position: relative;
        font-size: 20px;
        svg{
          &:first-child{
            position: absolute;
            left: 20px;
          }
        }
        &:hover{
          svg{
            font-size: 1.5rem;
          }
        }
      }

    }
  }
}
.Absolutefooter{
  padding-top: 1.5rem;
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: left;
    color: #fff;
  }
  ul{
    display: flex;
    list-style: none;
    li{
      width: 36px;
      height: 36px;
      background: $primaryColor;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      border-radius: 8px;
    }
  }
}
.AbsolutefooterContact{
  padding-top: 1.5rem;
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: left;
    color: #fff;
  }
  ul{
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: flex-end;
    li{
      width: 36px;
      height: 36px;
      background: $primaryColor;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      border-radius: 8px;
    }
  }
}

//COntactUs Page
.contactUs{
  //background: url("../img/heroBG.png");
  background-image:linear-gradient(to right, transparent 50%, #171717 50%), url("../img/heroBG.png");
  /* note the behavior of the container within small screens. you may cure the layout via : */
  width:100%;
  display:table;
  padding-top: 168px;
  padding-bottom:0px ;
  .contactUsHeading{
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    img{
      max-height: 428px;
      margin-inline: auto;


    }
      h1{
        font-family: 'GeneralSans-Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 64px;
        color: #fff;
        margin-bottom: 100px;
        span{
          color: $primaryColor;
        }

      }
  }
  .heading{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    div{
      margin-top: 0px;
      position: relative;
      padding-bottom: 1.5rem;
      h1{
        font-family: 'GeneralSans-Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;
        color: #fff;
        span{
          color: $primaryColor;
        }
        &:after{
          content: "";
          position: absolute;
          width: 130px;
          height: 2px;
          left: 0;
          bottom: 0;
          background: #FFFFFF66;


        }
      }

    }
    .row {
      --bs-gutter-x: 20px;
     ;
    }
    p{
      color: #fff;
      font-family: 'GeneralSans-Regular';
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: left;
      padding-top: 1.5rem;
      margin-bottom: 0rem;

    }
  }
  .form{

    label{
      color: #fff;
      font-family: 'GeneralSans-Regular';
      font-weight: 400;
      font-size: 18px;
    }
    input{
      color: #fff;
      border-color: transparent;
      background: #0E0E0E;
      height: 56px;
    }
    textarea{
      color: #fff;
      border-color: transparent;
      background: #0E0E0E;
      margin-bottom: 3rem;
    }
    .contacthr{
      margin-bottom: 1.5rem;
    }
    button{
      background: $primaryColor;
      color: #fff;
      min-width: 180px;
      font-family: 'GeneralSans-Semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      min-height: 48px;
    }
    .nameInfocontact{
      margin-block: 62px;
      margin-bottom: 38px;
      svg{
        margin-right: 17px;

      }
      div{
        padding-bottom: 0px;
        h6{font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          margin-bottom: 8px;
          /* identical to box height, or 18px */


          color: #FFFFFF;
        }
        p{
          padding-top: 0px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 100%;
          /* identical to box height, or 18px */
          margin-bottom: 0px;


          color: #FFFFFF;
        }

      }

    }
  }

}

@media (max-width: 991.999px) {

  .aboutUsSection{
    .row {
      .aboutDescription{
        padding:28px ;
        .title{
          h3{
            text-align: center;
            &:after{
              left: 50%;
              right: 50%;
              transform: translate(-50%,50%);
            }
          }
        }
        p{
          text-align: center;
        }
      }
    }
  }
}


@media (max-width: 767.999px) {
  .OurClient .row .servicesintro p{font-size: 14px}
  header{
    margin-top: 1rem;
    position: relative;
    .navbar{
      .navbar-collapse{
        display: none;
      }
      .mobil-nav{
        display: block;
        align-items: center;
        ul{
          display: flex;
          list-style: none;
          margin-bottom: 0px;
          li{
            width: 36px;
            height: 36px;
            background: $primaryColor;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            border-radius: 8px;
          }
        }

      }
    }
  }
  .herosection{
    background: url("../img/heroBG.png");
    padding-top: 48px;
    padding-bottom:70px ;
    .heading{
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      text-align: left;
      div{
        position: relative;
        padding-bottom: 1.5rem;
        h1{
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 42px;
          line-height: 60px;
          color: #fff;
          span{
            color: $primaryColor;
          }
          &:after{
            content: "";
            position: absolute;
            width: 130px;
            height: 2px;
            left: 0;
            bottom: 0;
            background: #FFFFFF66;


          }
        }

      }
      p{
        color: #fff;
        font-family: 'GeneralSans-Regular';
        font-size: 14px;
        line-height: 2rem;
        text-align: left;
        padding-top: 1.5rem;
        margin-bottom: 0rem;

      }
      .scrolIcon{
        display: none;
      }

    }
  }
  .aboutUsSection{
    .row{
      img{

        margin-inline: auto;

    }
    .title{
      h3{
        text-align: center!important;
        font-size: 24px!important;
        line-height: 60px!important;
        margin-bottom: 1rem;
        &:after{
          left: 50%!important;
          right: 50%;
          transform: translate(-50%,-50%);
        }
      }


    }
      p{
        font-size: 14px!important;
        text-align: center;
        line-height: 24px!important;

      }

    }
  }
  .services{
    padding-block: 4.5rem;
    .row{
      .servicesintro{
        .title{
          position: relative;
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          h3{
            font-family: 'GeneralSans-Semibold';
            font-style: normal;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 60px;
            text-align: left;
            margin-bottom: 0rem;
            color: #fff;
            span{
              color: $primaryColor;
            }
            &:after{
              content: "";
              position: absolute;
              width: 130px;
              height: 2px;
              left: 50%;
              right: 50%;
              transform: translate(-50%, -50%);
              bottom: 0;
              background: #FFFFFF66;
            }
          }

        }
        p{
          color: #fff;
          font-family: 'GeneralSans-Regular';
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 4rem;

        }
      }
      .card{
        background: #171717;
        padding-top: 74px;
        height: 100%;
        border-radius: 1rem;
        img{
          width: auto;
          margin: auto;
          margin-bottom: 2.7rem;
        }
        .card-img-top0{
          content: url("../img/cardImg1.png");
          max-height: 172px;
        }.card-img-top1{
           content: url("../img/cardImg2.png");
         }.card-img-top2{
            content: url("../img/cardImg3.png");
          }
        .card-body{
          .card-title{
            color: #fff;
            font-family: 'GeneralSans-Semibold';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            text-align: center;
            span{
              color: $primaryColor;
            }
          }
          .card-text{
            color: #fff;
            font-family: 'GeneralSans-Regular';
            font-size: 14px;
            line-height: 2rem;
            text-align: center;
            margin-bottom: 0rem;
          }
        }
        &:hover{
          background: #535B8C;
        }
      }
    }

  }
  .OurClient{
    padding-block: 4.5rem;
    background: #171717;
    .row{
      .servicesintro{
        .title{
          position: relative;
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          h3{
            font-family: 'GeneralSans-Semibold';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 1.5rem;
            color: #fff;
            span{
              color: $primaryColor;
            }
            &:after{
              content: "";
              position: absolute;
              width: 130px;
              height: 2px;
              left: 50%;
              right: 50%;
              transform: translate(-50%, -50%);
              bottom: 0;
              background: #FFFFFF66;
            }
          }

        }
        p{
          color: #fff;
          font-family: 'GeneralSans-Regular';
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          margin-bottom: 4rem;

        }
      }
      .Doublecard{
        height: 100%;
        .card{
          max-height: 50%;
          min-height: 210px;
          padding-top: 0px;
          margin-bottom: 20px;
          padding-bottom: 0px;
          img{
            min-height: 116px;
            object-fit: contain;
            margin: auto;
          }
        }
      }
      .card{
        border-radius: 1rem;
        background: #0E0E0E;
        padding-block: 48px;
        height: 100%;
        img{
          width: auto;
          margin: auto;
          margin-bottom: 20px;
        }
        .card-img-top0{
          content: url("../img/cardImg1.png");
          max-height: 172px;
        }.card-img-top1{
           content: url("../img/cardImg2.png");
         }.card-img-top2{
            content: url("../img/cardImg3.png");
          }
        .card-body{
          .card-title{
            color: #fff;
            font-family: 'GeneralSans-Semibold';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            span{
              color: $primaryColor;
            }
          }
          .card-text{
            color: #fff;
            font-family: 'GeneralSans-Regular';
            font-size: 1.125rem;
            line-height: 2rem;
            text-align: center;
            margin-bottom: 0rem;
          }
        }
      }
    }

  }
  .Testimonials{
    padding-block: 4.5rem;
    background: #171717;
    .row{
      .servicesintro{
        .title{
          position: relative;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          h3{
            font-family: 'GeneralSans-Semibold';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 1.5rem;
            color: #fff;
            span{
              color: $primaryColor;
            }
            &:after{
              content: "";
              position: absolute;
              width: 130px;
              height: 2px;
              left: 50%;
              right: 50%;
              transform: translate(-50%, -50%);
              bottom: 0;
              background: #FFFFFF66;
            }
          }

        }
        p{
          color: #fff;
          font-family: 'GeneralSans-Regular';
          font-size: 14px;
          line-height: 2rem;
          text-align: center;
          margin-bottom: 0rem;

        }
      }
    }
    .slider{
      position: relative;
      padding-block: 5rem;
      background: url("../img/stars.png");
      background-size: cover;
      padding-top: 4rem;
      .featureslider_nextarrow{
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translate(50%,50%);
      }
      .featureslider_prevarrow{
        z-index: 10;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(50%,50%);

      }
      .slide{
        position: relative;
        .avatarimg{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            width: 200px;
            height: 200px;
            border-radius: 50%;
            margin-bottom: 2rem;
          }
          h5{
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            color: #fff;
          }
          p{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $primaryColor;
            margin-bottom: 2.5rem;
          }
        }
        .rating{
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            margin-inline: 5px;
          }
        }
        .description{
          width: 80%;
          position: relative;
          margin-inline:auto ;
          padding-block: 40px;
          p{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
          }
          &:before{
            position: absolute;
            background : url(../img/startcolin.png) no-repeat;
            content: '';
            background-size: 82px 64px;
            display: inline-block;
            width: 82px;
            height: 64px;
            padding: 0 7px;
            left: -20px;
            top: 0px;

          }
          &::after{
            position: absolute;
            background : url(../img/endcolin.png) no-repeat;
            background-size: 82px 64px;
            display: inline-block;
            width: 82px;
            height: 64px;
            content : "";
            bottom: 20px;
            right: 0;
            padding: 0 7px;

          }


        }
      }
    }

  }
  .footer1{
    .widget1{
      margin-bottom: 3rem;
      p{
        max-width: 100%;
      }
    }
    .widget2{
      margin-bottom: 3rem;

    }
  }

  .Absolutefooter{
    p{
      font-size: 14px;
    }

    ul{
      padding-left: 0px;

    }
  }
  .AbsolutefooterContact{
    ul{
      padding-left: 0px;
      margin-left: auto;
    }
  }



  //COntactUs Page
  .contactUs{
    //background: url("../img/heroBG.png");
    background-image:none;
    /* note the behavior of the container within small screens. you may cure the layout via : */
    width:100%;
    padding-top: 140px;
    padding-bottom:0px ;
    .contactUsHeading{
      background-image:url("../img/heroBG.png");
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      text-align: left;
      padding-inline: 20px;
      h1{
        font-family: 'GeneralSans-Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 56px;
        color: #fff;
        span{
          color: $primaryColor;
        }

      }
      img{
        margin-top: 40px;
        margin-bottom: 100px;
      }
    }
    .heading{
      background: #171717 ;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      text-align: left;
      padding-block: 1.5rem;
      padding-top: 70px;
      div{
        position: relative;
        padding-bottom: 1.5rem;
        h1{
          font-family: 'GeneralSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 36px;
          color: #fff;
          span{
            color: $primaryColor;
          }
          &:after{
            content: "";
            position: absolute;
            width: 130px;
            height: 2px;
            left: 0;
            bottom: 0;
            background: #FFFFFF66;


          }
        }

      }

      p{
        color: #fff;
        font-family: 'GeneralSans-Regular';
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: left;
        padding-top: 1.5rem;
        margin-bottom: 0rem;

      }
    }
    .form{
      .nameInfocontact{
        margin-bottom: 0px;
        margin-top: 50px;
        padding-bottom: 0px;

      }


      label{
        color: #fff;
      }
      input{
        color: #fff;
        border-color: transparent;
        background: #0E0E0E;
        height: 56px;

      }
      textarea{
        color: #fff;
        border-color: transparent;
        background: #0E0E0E;
        margin-bottom: 0px;

      }
      .contacthr{
        margin-bottom: 40px;
      }
      div{
        margin-bottom: 1.5rem;
      }
      .row{
        --bs-gutter-x: 0px;
      }
      button{
        background: $primaryColor;
        color: #fff;
        min-width: 100%;
        font-family: 'GeneralSans-Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        min-height: 56px;
      }
    }
    .contactUs .form .nameInfocontact{
      margin-bottom: 0px!important;
    }

  }
  .Absolutefooter{
    .container{
      padding-inline: 20px;
    }
  }




}
@media (max-width: 576.99px) {
  .Testimonials .slider .featureslider_nextarrow{
    top: 276px;
    right: 20px;
  }
  .Testimonials .slider .featureslider_prevarrow{
    top: 276px;
    left: -30px;
  }
  .container{
    padding-inline: 20px;
  }

  .OurClient{
    padding-bottom: 0px;
  }
  .footer1{
  }

  .Absolutefooter{
  }

  .contactUs{
    .container{
      padding: 0px;
    .heading{
      padding-inline: 20px;
    }
    }
  }



}
