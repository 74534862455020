$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1300px
);
@media (min-width: 1400px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  max-width: 1300px;
}
}
$pimaryBg:#0E0E0E;
$primaryColor:#9DA6CE;
